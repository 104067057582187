<template>
  <div class="form-container">
     <img src="../assets/eplogoo.png" style="width: 200px; height: auto;">
      
    <h1>EP REAL ESTATE</h1>
    <h2>TENANT'S ACQUAINTANCE FORM</h2>
    <form @submit.prevent="generatePdf">

      <div class="form-group">
        <label for="signature">Upload your passport here:</label>
        <input type="file" id="passport" @change="handleFileUpload" required>
      </div>
      <div class="form-group">
        <label for="applicantName">1. APPLICANT'S NAME:</label>
        <input type="text" id="applicantName" v-model="form.applicantName" required>
      </div>
      <div class="form-group">
        <label for="address">2. PRESENT RESIDENTIAL ADDRESS:</label>
        <input type="text" id="address" v-model="form.address" required>
      </div>
      <div class="form-group">
        <label for="stateOfOrigin">3. STATE OF ORIGIN:</label>
        <input type="text" id="stateOfOrigin" v-model="form.stateOfOrigin" required>
      </div>
      <div class="form-group">
        <label for="homeTown">4. HOME TOWN:</label>
        <input type="text" id="homeTown" v-model="form.homeTown" required>
      </div>
      <div class="form-group">
        <label for="religion">5. RELIGION:</label>
        <input type="text" id="religion" v-model="form.religion" required>
      </div>
      <div class="form-group">
        <label for="placeOfWorship">6. PLACE OF WORSHIP:</label>
        <input type="text" id="placeOfWorship" v-model="form.placeOfWorship" required>
      </div>
      <div class="form-group">
        <label for="phoneNumber">7. TELEPHONE NUMBER:</label>
        <input type="tel" id="phoneNumber" v-model="form.phoneNumber" required>
      </div>
      <div class="form-group">
        <label for="email">8. E-MAIL ADDRESS:</label>
        <input type="email" id="email" v-model="form.email" required>
      </div>
      <div class="form-group">
        <label for="maritalStatus">9. MARITAL STATUS:</label>
        <input type="text" id="maritalStatus" v-model="form.maritalStatus" required>
      </div>
      <div class="form-group">
        <label for="occupation">10. OCCUPATION/PROFESSION:</label>
        <input type="text" id="occupation" v-model="form.occupation" required>
      </div>
      <div class="form-group">
        <label for="employer">11. NAME & ADDRESS OF PRESENT EMPLOYER:</label>
        <input type="text" id="employer" v-model="form.employer" required>
      </div>
      <div class="form-group">
        <label for="jobTitle">12. JOB TITLE/DESCRIPTION:</label>
        <input type="text" id="jobTitle" v-model="form.jobTitle" required>
      </div>
      <div class="form-group">
        <label for="yearsOfEmployment">13. NO. OF YEARS IN EMPLOYMENT:</label>
        <input type="text" id="yearsOfEmployment" v-model="form.yearsOfEmployment" required>
      </div>


      <div class="form-group">
        <label for="yearsOfEmployment">14. IF SELF EMPLOYED, STATE NATURE OF BUSINESS:</label>
        <input type="text" id="natureOfbusiness" v-model="form.natureOfbusiness" required>
      </div>

<div class="form-group">
        <label for="yearsOfEmployment">15. DATE ESTABLISHED:</label>
        <VueDatePicker v-model='selectedDate' id='date' range> </VueDatePicker>
        
      </div>

<div class="form-group">
        <label for="yearsOfEmployment">16. INCOPORATION NUMBER (FOR CORPORATE CLIENT):</label>
        <input type="text" id="incNumber" v-model="form.incNumber" required>
      </div>

      <div class="form-group">
        <label for="yearsOfEmployment">17. NAME AND ADDRESS OF BANKERS:</label>
        <input type="text" id="addOfbankers" v-model="form.addOfbankers" required>
      </div>

      <div class="form-group">
        <label for="yearsOfEmployment">18. NO OF DEPENDANTS:</label>
        <input type="text" id="noOfdependants" v-model="form.noOfdependants" required>
      </div>



      <div class="form-group">
        <label for="yearsOfEmployment">19. NO OF YEARS IN FORMER PLACE OF RESIDENCE:</label>
        <input type="number" id="yearsInFormer" v-model="form.yearsInFormer" required>
      </div>

      <div class="form-group">
        <label for="yearsOfEmployment">20. REASONS FOR WANTING TO LEAVE YOUR CURRENT RESIDENCE:</label>
        <input type="text" id="reasonsForleaving" v-model="form.reasonsForleaving" required>
      </div>

      <div class="form-group">
        <label for="yearsOfEmployment">21. NAME OF LANDLORD/MANAGING AGENT:</label>
        <input type="text" id="landlordName" v-model="form.landlordName" required>
      </div>
      
<div class="form-group">
        <label for="yearsOfEmployment">22. PHONE NUMBER OF LANDLORD/MANAGING AGENT :</label>
        <input type="tel" id="landlordNumber" v-model="form.reference" required>
      </div>

      <div class="form-group">
        <label for="yearsOfEmployment">23.  NEXT OF KIN:</label>
        <input type="text" id="nextOfkin" v-model="form.nextOfkin" required>
      </div>

      <div class="form-group">
        <label for="yearsOfEmployment">24.  NEXT OF KIN PHONE NUMBER:</label>
        <input type="tel" id="nextOfkinNumber" v-model="form.nextOfkin" required>
      </div>



<div class="form-group">
        <label for="yearsOfEmployment">25.FIRST REFERENCE:</label>
        <input type="text" id="reference" v-model="form.reference" required>
      </div>



<div class="form-group">
        <label for="yearsOfEmployment">26. REFEREE PHONE NUMBER:</label>
        <input type="tel" id="referenceNumber" v-model="form.reference" required>
      </div>


<div class="form-group">
        <label for="yearsOfEmployment">27. REFEREE ADDRESS:</label>
        <input type="text" id="referenceAdd" v-model="form.reference" required>
      </div>



  <div class="form-group">
        <label for="yearsOfEmployment">28.SECOND REFERENCE:</label>
        <input type="text" id="reference2" v-model="form.reference" required>
      </div>



<div class="form-group">
        <label for="yearsOfEmployment">29. SECOND REFEREE PHONE NUMBER:</label>
        <input type="tel" id="referenceNumber2" v-model="form.reference" required>
      </div>


<div class="form-group">
        <label for="yearsOfEmployment">30. SECOND REFEREE ADDRESS:</label>
        <input type="text" id="referenceAdd2" v-model="form.reference" required>
      </div>    


  <div class="form-group">
        <label for="signature">Kindly upload your ID card:</label>
        <input type="file" id="signature" @change="handleFileUpload" required>
      </div>

         
      <div class="form-group">
        <label for="signature">Kindly upload your SIGNATURE:</label>
        <input type="file" id="signature" @change="handleFileUpload" required>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
  components:{
 VueDatePicker
   },
  data() {
    return {
      form: {
        applicantName: '',
        address: '',
        stateOfOrigin: '',
        homeTown: '',
        religion: '',
        placeOfWorship: '',
        phoneNumber: '',
        email: '',
        maritalStatus: '',
        occupation: '',
        employer: '',
        jobTitle: '',
        yearsOfEmployment: '',
        natureOfbusiness:'',
        
        // selectedDate:null,
        incNumber:'',
        addOfbankers:'',
        noOfdependants:'',
        yearsInformer:'',
        reasonsForleaving:'',
        landlordName:'',
        landlordNumber:'',
        nextOfkin:'',
        nextOfkinNumber:'',
        
        reference:'',
        
        referenceAdd:'',
        referenceNumber:'',
        reference2:'',
        
        referenceAdd2:'',
        referenceNumber2:'',
      },
      signature: null,
      passport:null,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.signature = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    generatePdf() {
      const pdfContent = document.createElement('div');
      pdfContent.style.width = '500px';
      pdfContent.innerHTML = `
     <h1>    <img src="../assets/eplogoo.png" style="width: 200px; height: auto;">
      
        
         EP REAL ESTATE
          <img src="${this.passport}" alt="Signature" style="width: 200px; height: auto;">   
        TENANT'S ACQUAINTANCE FORM </h1> </br>
        <p><strong>1. APPLICANT'S NAME:</strong> ${this.form.applicantName}</p>
        <p><strong>2. PRESENT RESIDENTIAL ADDRESS:</strong> ${this.form.address}</p>
        <p><strong>3. STATE OF ORIGIN:</strong> ${this.form.stateOfOrigin}</p>
        <p><strong>4. HOME TOWN:</strong> ${this.form.homeTown}</p>
        <p><strong>5. RELIGION:</strong> ${this.form.religion}</p>
        <p><strong>6. PLACE OF WORSHIP:</strong> ${this.form.placeOfWorship}</p>
        <p><strong>7. TELEPHONE NUMBER:</strong> ${this.form.phoneNumber}</p>
        <p><strong>8. E-MAIL ADDRESS:</strong> ${this.form.email}</p>
        <p><strong>9. MARITAL STATUS:</strong> ${this.form.maritalStatus}</p>
        <p><strong>10. OCCUPATION/PROFESSION:</strong> ${this.form.occupation}</p>
        <p><strong>11. NAME & ADDRESS OF PRESENT EMPLOYER:</strong> ${this.form.employer}</p>
        <p><strong>12. JOB TITLE/DESCRIPTION:</strong> ${this.form.jobTitle}</p>
        <p><strong>13. NO. OF YEARS IN EMPLOYMENT:</strong> ${this.form.yearsOfEmployment}</p>
        <p><strong>14. IF SELF EMPLOYED, STATE NATURE OF BUSINESS:</strong> ${this.form.natureOfbusiness}</p>
        <p><strong>15. DATE ESTABLISHED:</strong> ${this.form.selectedDate}</p>
        <p><strong>16. INCOPORATION NUMBER (FOR CORPORATE CLIENT):</strong> ${this.form.incNumber}</p>
        <p><strong>17. NAME AND ADDRESS OF BANKERS:</strong> ${this.form.addOfbankers}</p>
        <p><strong>18. NO OF DEPENDANTS:</strong> ${this.form.noOfdependants}</p>
        <p><strong>19. NO OF YEARS IN FORMER PLACE OF RESIDENCE:</strong> ${this.form.yearsInformer}</p>
        <p><strong>20. REASONS FOR WANTING TO LEAVE YOUR CURRENT RESIDENCE::</strong> ${this.form.reasonsForleaving}</p>
        <p><strong>21. NAME OF LANDLORD/MANAGING AGENT:</strong> ${this.form.landlordName}</p>
        <p><strong>22. PHONE NUMBER OF LANDLORD/MANAGING AGENT:</strong> ${this.form.landlordNumber}</p>
        
        <p><strong>23.  NEXT OF KIN:</strong> ${this.form.nextOfkin}</p>
        <p><strong>24. NEXT OF KIN PHONE NUMBER:</strong> ${this.form.nextOfkinNumber}</p>
        <p><strong>25. REFERENCE:</strong> ${this.form.reference}</p>
       <p><strong>26. PHONE NUMBER OF REFEREE:</strong> ${this.form.referenceNumber}</p>
      <p><strong>27. ADDRESS OF REFEREE:</strong> ${this.form.referenceAdd}</p>

         <p><strong>28.sECOND REFERENCE:</strong> ${this.form.reference2}</p>
       <p><strong>29. PHONE NUMBER OF SECOND REFEREE:</strong> ${this.form.referenceNumber2}</p>
      <p><strong>30. ADDRESS OF SECOND REFEREE:</strong> ${this.form.referenceAdd2}</p>

        <p><strong>DECLARATION:  <br>
         I hereby declare that all the information given on this form is correct and agree to abide with the terms 
         and conditions as stated in the tenancy agreement </strong><p>
        <p><strong>SIGNATURE:</strong></p>
        <img src="${this.signature}" alt="Signature" style="width: 200px; height: auto;">
      `;

      document.body.appendChild(pdfContent);

      html2canvas(pdfContent).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save('tenant-form.pdf');
        document.body.removeChild(pdfContent);
      });
    },
  },
};
</script>

<style>
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.form-group {
  margin-bottom: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type="text"], input[type="email"], input[type="file"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}

</style>
